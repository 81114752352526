import React from 'react';
// import section header
// import sections
import HeroFull from '../components/sections/HeroFull02';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import PortfolioSection from '../components/sections/Portfolio';
import ExperimentsSection from '../components/sections/Experiments';

class Portfolio extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  
    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" >Our Portfolio</HeroFull>
        <GenericSection>
          <div className="container-xs">
            <p>A selection of our past investments. Projects listed here received strategic or seed investment from Chainstride.</p>
          </div>
        </GenericSection>


        <PortfolioSection />
        
        <GenericSection>
          <div className="container-xs center-content">
            <h2 class="m-0 reveal-from-bottom is-revealed">Our Experiments</h2>
          </div>
        </GenericSection>
        <ExperimentsSection />
        <GenericSection />

        <Cta split />
      </React.Fragment>
    );
  }
}


export default Portfolio;