import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class ExperimentsSection extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'clients section reveal-fade',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'clients-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <ul className="list-reset">
              <li>
              <Image
                  src={require('./../../assets/images/seadoge.png')}
                  alt="Client 01"
                  width={100}
                  height={100} />
              </li>
              <li>
              <Image
                  src={require('./../../assets/images/shibance-logo-rev.svg')}
                  alt="Client 01"
                  width={100}
                  height={100} />
              </li>
            </ul>
          </div>
        </div>

      </section>
    );
  }
}

ExperimentsSection.propTypes = propTypes;
ExperimentsSection.defaultProps = defaultProps;

export default ExperimentsSection;