import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull02';
import Testimonial from '../components/sections/Testimonial';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Services extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" >Our Services</HeroFull>

        <GenericSection>
          <div className="container-xs">
          <h4>Promotional</h4>
            <p>
              Chainstride works with hand-picked social influencers, thought leaders, YouTube channels, blogs, and many more. As a result, we're able to use a multi-pronged approach in order to organically grow the audience of our investees.
            </p>
            <h4>Advisory &amp; Networking</h4>
            <p>
              Our founders and partners have worked extensively in the startup space, both in crypto and in more traditional startups. We're able to offer strategy advice, and have contacts at market makers, exchanges, and large crypto businesses.
            </p>
            
            <h4>Engineering</h4>
            <p>
              Chainstride retains several engineers, including experienced blockchain engineers. As such we're able to offer engineering advice and support to our investees. This may include, but is not limited to:
            </p>
            <ul>
              <li>Smart contract auditing and testing</li>
              <li>Architectural advice for large applications</li>
              <li>Full-stack Web3 applications, from frontend, to server-side, L2s, cross-chain bridges, liquidity pools, etc.</li>
            </ul>
          </div>
        </GenericSection>

        <Cta split />
      </React.Fragment>
    );
  }
}

export default Services;