import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import GenericSection from '../components/sections/GenericSection';
// import some required elements
import Input from '../components/elements/Input';
import Button from '../components/elements/Button';
import HeroFull from '../components/sections/HeroFull02';

class Contact extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  
    return (
    <React.Fragment>
        <HeroFull className="illustration-section-01" >Contact Us</HeroFull>

        <GenericSection>
          <div className="container-xs">
            <SectionHeader data={"Ayy"} className="center-content" />
            <form style={formStyle}>
              <div className="mb-24">

                <div className="flex">
                <Input
                  type="text"
                  label="This is a label"
                  placeholder="Your name"
                  formGroup="desktop"
                  labelHidden />
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden>
                  
                </Input>

                <Input
                  type="textarea"
                  label="This is a label"
                  placeholder="Your message"
                  formGroup="desktop"
                  labelHidden>
                  
                </Input>
                <Button className={"button-light"}>Send</Button>
                
                </div>

              </div>
            </form>
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Contact;